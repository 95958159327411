/**
 * NOTIFICATION TOAST 
 */

.notification-toast {
  position: fixed;
  bottom: 80px;
  left: 20px;
  right: 20px;
  background: var(--white);
  max-width: 300px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 15px;
  padding: 15px;
  -webkit-border-radius: var(--border-radius-md);
  border-radius: var(--border-radius-md);
  -webkit-box-shadow: 0 5px 20px hsla(0, 0%, 0%, 0.15);
  box-shadow: 0 5px 20px hsla(0, 0%, 0%, 0.15);
  -webkit-transform: translateX(-webkit-calc(-100% - 40px));
  -ms-transform: translateX(calc(-100% - 40px));
  transform: translateX(calc(-100% - 40px));
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  z-index: 5;
  -webkit-animation: slideInOut 10s ease-in-out infinite;
  animation: slideInOut 10s ease-in-out infinite;
  /* padding-bottom: 0; */
}

@-webkit-keyframes slideInOut {
  0%,
  45%,
  100% {
    -webkit-transform: translateX(-webkit-calc(-100% - 40px));
    transform: translateX(calc(-100% - 40px));
    opacity: 0;
    visibility: hidden;
  }

  50%,
  95% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slideInOut {
  0%,
  45%,
  100% {
    -webkit-transform: translateX(-webkit-calc(-100% - 40px));
    transform: translateX(calc(-100% - 40px));
    opacity: 0;
    visibility: hidden;
  }

  50%,
  95% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

.notification-toast.closed {
  display: none;
}

.toast-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--sonic-silver);
}

.toast-close-btn ion-icon {
  --ionicon-stroke-width: 50px;
}

.toast-banner {
  width: 70px;
  height: 70px;
  border: 1px solid var(--cultured);
  -webkit-border-radius: var(--border-radius-sm);
  border-radius: var(--border-radius-sm);
}

.toast-banner img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.toast-detail {
  width: -webkit-calc(100% - 85px);
  width: calc(100% - 85px);
  padding-right: 10px;
}

.toast-message {
  font-size: var(--fs-10);
  color: var(--sonic-silver);
  margin-bottom: 8px;
}

.toast-title {
  font-size: var(--fs-7);
  font-weight: var(--weight-500);
  color: var(--onyx);
  margin-bottom: 2px;
}

.toast-meta {
  font-size: var(--fs-10);
  color: var(--sonic-silver);
}
@media (min-width: 1024px) {
  .notification-toast {
    bottom: 30px;
  }
}
