.rating-modal {
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.rating-modal.fade:not(.show) {
  opacity: 0;
}

.rating-modal.fade.show {
  opacity: 1;
}
