/*-----------------------------------*\
  #CATEGORY
\*-----------------------------------*/
/* Style the scrollbar */
.category-item-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.category-item-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.category-item-container::-webkit-scrollbar-thumb {
  background: #b0aeae;
  border-radius: 5px;
}
.category-item-container::-webkit-scrollbar-thumb:hover {
  background: #b0aeae;
}

.category {
  margin-bottom: 30px;
  overflow-y: hidden;
}

.category-item-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  overflow: auto hidden;
  -webkit-scroll-snap-type: inline mandatory;
  -ms-scroll-snap-type: inline mandatory;
  scroll-snap-type: inline mandatory;
  overscroll-behavior-inline: contain;
}

.category-item {
  min-width: 45%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 6px;
  padding: 6px;
  border: 1px solid var(--cultured);
  -webkit-border-radius: var(--border-radius-md);
  border-radius: var(--border-radius-md);
  scroll-snap-align: start;
  margin-bottom: 2px;
}

.category-img-box {
  background: var(--cultured);
  border: 1px solid hsl(0, 0%, 80%);
  padding: 10px;
  -webkit-border-radius: var(--border-radius-sm);
  border-radius: var(--border-radius-sm);
}

.category-content-box {
  width: 100%;
}

.category-content-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.category-item-title {
  color: var(--eerie-black);
  font-size: 12px;
  /* font-weight: var(--weight-600); */
  /* text-transform: uppercase; */
}

.category-item-amount {
  color: var(--sonic-silver);
  font-size: var(--fs-11);
}

.category-btn {
  color: var(--salmon-pink);
  font-size: var(--fs-9);
  font-weight: var(--weight-500);
  text-transform: capitalize;
}

@media (min-width: 570px) {
  /**
   * #CATEGORY
   */

  .category-item-container {
    gap: 30px;
  }

  .category-item {
    min-width: -webkit-calc(45% - 15px);
    min-width: calc(45% - 15px);
  }
}
@media (min-width: 768px) {
  /**
   * #CATEGORY 
   */

  .category-img-box {
    padding: 20px;
  }
}
@media (min-width: 1024px) {
  /**
   * #CATEGORY 
   */

  .category-item {
    min-width: -webkit-calc(33.33% - 20px);
    min-width: calc(33.33% - 20px);
  }

  .category-img-box {
    padding: 10px;
  }
}
@media (min-width: 1200px) {
  /**
   * #CATEGORY
   */

  .category-item {
    min-width: -webkit-calc(25% - 22.5px);
    min-width: calc(25% - 22.5px);
  }

  .category-item-title {
    font-size: 12px;
  }
}
