/*-----------------------------------*\
  #PRODUCT GRID
\*-----------------------------------*/

.product-main {
  margin-bottom: 30px;
}

.product-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.showcase {
  border: 1px solid var(--cultured);
  -webkit-border-radius: var(--border-radius-md);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  -webkit-transition: var(--transition-timing);
  -o-transition: var(--transition-timing);
  transition: var(--transition-timing);
}

.showcase:hover {
  -webkit-box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1);
  box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1);
}

.showcase-banner {
  position: relative;
}

.product-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: var(--transition-timing);
  -o-transition: var(--transition-timing);
  transition: var(--transition-timing);
}

.product-img.default {
  position: relative;
  z-index: 1;
}

.product-img.hover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}

.showcase:hover .product-img.hover {
  opacity: 1;
}

.showcase:hover .product-img.default {
  opacity: 0;
}

.showcase:hover .product-img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.showcase-badge {
  position: absolute;
  top: 15px;
  left: 15px;
  background: var(--ocean-green);
  font-size: var(--fs-8);
  font-weight: var(--weight-500);
  color: var(--white);
  padding: 0 8px;
  -webkit-border-radius: var(--border-radius-sm);
  border-radius: var(--border-radius-sm);
  z-index: 3;
}

.showcase-badge.angle {
  top: 8px;
  left: -29px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  text-transform: uppercase;
  font-size: 11px;
  padding: 5px 40px;
}

.showcase-badge.black {
  background: var(--eerie-black);
}

.showcase-badge.pink {
  background: var(--salmon-pink);
}

.showcase-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: var(--transition-timing);
  -o-transition: var(--transition-timing);
  transition: var(--transition-timing);
  z-index: 3;
}

.btn-action {
  background: var(--white);
  color: var(--sonic-silver);
  margin-bottom: 5px;
  border: 1px solid var(--cultured);
  padding: 5px;
  -webkit-border-radius: var(--border-radius-sm);
  border-radius: var(--border-radius-sm);
  -webkit-transition: var(--transition-timing);
  -o-transition: var(--transition-timing);
  transition: var(--transition-timing);
}

.btn-action:hover {
  background: var(--eerie-black);
  color: var(--white);
  border-color: var(--eerie-black);
}

.showcase-content {
  padding: 15px 20px 8px;
}

.showcase-category {
  color: var(--salmon-pink);
  font-size: var(--fs-9);
  font-weight: var(--weight-500);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.showcase-title {
  color: var(--sonic-silver);
  font-size: var(--fs-8);
  font-weight: var(--weight-300);
  text-transform: capitalize !important;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: var(--transition-timing);
  -o-transition: var(--transition-timing);
  transition: var(--transition-timing);
}

.showcase-title:hover {
  color: var(--eerie-black);
}

.showcase-rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: var(--sandy-brown);
  margin-bottom: 10px;
}

.price-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: block;
  gap: 10px;
  font-size: var(--fs-7);
  color: var(--eerie-black);
  margin-bottom: 0;
}

.price {
  font-weight: var(--weight-700);
  margin-bottom: 0;
}

del {
  color: var(--sonic-silver);
}
@media (min-width: 480px) {
  /**
   * #PRODUCT 
   */

  .product-grid {
    -ms-grid-columns: 1fr 30px 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}
@media (min-width: 570px) {
  /**
   * #PRODUCT
   */

  .product-minimal .showcase-container {
    min-width: 50%;
  }

  .product-featured .showcase-img {
    max-width: 450px;
    margin: auto;
  }

  .product-featured .countdown {
    gap: 20px;
  }
}
@media (min-width: 768px) {
  /**
   * #PRODUCT 
   */

  .product-minimal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 20px;
  }

  .product-minimal .product-showcase {
    min-width: -webkit-calc(50% - 10px);
    min-width: calc(50% - 10px);
    width: -webkit-calc(50% - 10px);
    width: calc(50% - 10px);
  }

  .product-minimal .showcase-container {
    min-width: 100%;
  }

  .product-featured .showcase {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
  }

  .product-featured .showcase-img {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  .product-featured .showcase-content {
    margin-top: 0;
    min-width: -webkit-calc(100% - 345px);
    min-width: calc(100% - 345px);
  }
}
@media (min-width: 1024px) {
  /**
   * #PRODUCT 
   */

  .product-container .container {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 30px;
  }

  .sidebar {
    --fs-5: 0.941rem;

    position: -webkit-sticky;

    position: sticky;
    top: 30px;
    left: 0;
    padding: 0;
    min-width: -webkit-calc(25% - 15px);
    min-width: calc(25% - 15px);
    margin-bottom: 30px;
    visibility: visible;
    overflow-y: auto;
    -ms-scroll-chaining: chained;
    overscroll-behavior: auto;
    z-index: 0;
  }

  .sidebar-category {
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid var(--cultured);
    -webkit-border-radius: var(--border-radius-md);
    border-radius: var(--border-radius-md);
  }

  .sidebar-close-btn {
    display: none;
  }

  .product-box {
    min-width: -webkit-calc(75% - 15px);
    min-width: calc(75% - 15px);
  }

  .product-minimal {
    margin-bottom: 20px;
  }

  .product-minimal .product-showcase {
    min-width: -webkit-calc(33.33% - 14px);
    min-width: calc(33.33% - 14px);
    width: -webkit-calc(33.33% - 14px);
    width: calc(33.33% - 14px);
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .product-minimal .showcase-wrapper {
    gap: 10px;
  }

  .product-minimal .showcase-container {
    padding: 2px;
  }

  .product-featured .countdown-content {
    padding: 5px 10px;
  }

  .product-grid {
    /* -ms-grid-columns: (1fr) [3]; */
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1200px) {
  /**
   * #PRODUCT
   */

  .product-featured .showcase > div {
    min-width: -webkit-calc(50% - 10px);
    min-width: calc(50% - 10px);
  }

  .product-featured .display-number {
    --fs-5: 1.125rem;
  }

  .product-grid {
    /* -ms-grid-columns: (1fr) [4]; */
    grid-template-columns: repeat(5, 1fr);
  }
}
