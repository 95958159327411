/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/* start details */
.home-link {
  -webkit-transition: color 0.5 ease;
  -o-transition: color 0.5 ease;
  transition: color 0.5 ease;
}

.home-link:hover,
.breadcrumb-item.active {
  color: var(--main-black) !important;
}

.discount {
  font-size: 0.9rem;
  margin-top: 3px;
}

.description {
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.color-choice {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  outline: 1.5px solid #eee;
}

.color-choice.active,
.color-choice:hover {
  outline-color: var(--main-black);
}

.terms-form input[type="checkbox"]:checked {
  background-color: var(--main-black);
  border-color: var(--main-black);
}

.terms-form input[type="checkbox"],
.terms-form input[type="checkbox"] + label {
  cursor: pointer;
}

.additional-info-list .key {
  width: 8.5rem;
}

.full-description-title::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--main-black);
  position: absolute;
  left: 0;
  bottom: 0;
}

@media (min-width: 992px) {
  .full-description {
    width: 75%;
  }
}

.zoom-btn {
  width: 2.5rem;
  height: 2.5rem;
  top: 1rem;
  right: 1rem;
  z-index: 100;
}

.img-indicator.active {
  border: 2px solid var(--yellow) !important;
}

.fullscreen-carousel {
  background-color: rgba(256, 256, 256, 0.5) !important;
}

@media (max-width: 991px) {
  .carousel-img {
    width: 400px;
  }
}

@media (max-width: 767px) {
  .carousel-img {
    max-width: 300px;
  }
}

.minimize-btn {
  z-index: 200;
  top: 2rem;
  right: 2rem;
}

.sticky-top {
  top: 6rem;
}
/* end details */
