/*-----------------------------------*\
  #PRODUCT FEATURED
\*-----------------------------------*/

/* Style the scrollbar */
.showcase-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.showcase-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.showcase-wrapper::-webkit-scrollbar-thumb {
  background: #b0aeae;
  border-radius: 5px;
}
.showcase-wrapper::-webkit-scrollbar-thumb:hover {
  background: #b0aeae;
}

.product-featured {
  margin-bottom: 30px;
  overflow-y: hidden;
}

.product-featured .showcase-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  -webkit-scroll-snap-type: inline mandatory;
  -ms-scroll-snap-type: inline mandatory;
  scroll-snap-type: inline mandatory;
}

.product-featured .showcase-container {
  min-width: 100%;
  padding: 10px;
  border: 1px solid var(--cultured);
  -webkit-border-radius: var(--border-radius-md);
  border-radius: var(--border-radius-md);
  scroll-snap-align: start;
  margin-bottom: 4px;
}

.product-featured .showcase-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.product-featured .showcase-content {
  margin-top: 30px;
}

.product-featured .showcase-rating {
  color: var(--sandy-brown);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  margin-bottom: 15px;
}

.product-featured .showcase-title {
  font-size: var(--fs-7);
  color: var(--eerie-black);
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin-bottom: 3px;
}

.product-featured .showcase-desc {
  color: var(--sonic-silver);
  font-size: var(--fs-7);
  font-weight: var(--weight-300);
  margin-bottom: 10px;
}

.product-featured .price-box {
  font-size: var(--fs-3);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.product-featured .price {
  color: var(--salmon-pink);
  font-weight: var(--weight-700);
}

.product-featured del {
  color: var(--sonic-silver);
  font-weight: var(--weight-300);
}

.product-featured .add-cart-btn {
  background: var(--salmon-pink);
  padding: 8px 15px;
  color: var(--white);
  font-weight: var(--fs-9);
  font-weight: var(--weight-700);
  text-transform: uppercase;
  -webkit-border-radius: var(--border-radius-md);
  border-radius: var(--border-radius-md);
  margin-bottom: 15px;
  -webkit-transition: var(--transition-timing);
  -o-transition: var(--transition-timing);
  transition: var(--transition-timing);
}

.product-featured .add-cart-btn:hover {
  background: var(--eerie-black);
  color: var(--white);
}

.product-featured .showcase-status {
  margin-bottom: 15px;
}

.product-featured .showcase-status .wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--eerie-black);
  font-size: var(--fs-9);
  font-weight: var(--weight-400);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.product-featured .showcase-status-bar {
  background: var(--cultured);
  position: relative;
  height: 10px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.product-featured .showcase-status-bar::before {
  position: absolute;
  content: "";
  top: 3px;
  left: 3px;
  height: 4px;
  width: 40%;
  background: var(--salmon-pink);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.product-featured .countdown-desc {
  color: var(--eerie-black);
  font-size: var(--fs-9);
  font-weight: var(--weight-600);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.product-featured .countdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
}

.product-featured .countdown-content {
  padding: 5px;
  background: var(--cultured);
  -webkit-border-radius: var(--border-radius-md);
  border-radius: var(--border-radius-md);
  text-align: center;
}

.product-featured .display-number {
  color: var(--eerie-black);
  font-size: var(--fs-5);
  font-weight: var(--weight-500);
  min-width: 40px;
  margin-bottom: 0;
}

.product-featured .display-text {
  color: var(--davys-gray);
  font-size: var(--fs-11);
  margin-bottom: 3px;
}
@media (min-width: 570px) {
  /**
   * #PRODUCT
   */

  .product-minimal .showcase-container {
    min-width: 50%;
  }

  .product-featured .showcase-img {
    max-width: 450px;
    margin: auto;
  }

  .product-featured .countdown {
    gap: 20px;
  }
}
@media (min-width: 768px) {
  .product-featured .showcase {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
  }

  .product-featured .showcase-img {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  .product-featured .showcase-content {
    margin-top: 0;
    min-width: -webkit-calc(100% - 345px);
    min-width: calc(100% - 345px);
  }
}
@media (min-width: 1024px) {
  .product-featured .countdown-content {
    padding: 5px 10px;
  }
}
@media (min-width: 1200px) {
  .product-featured .showcase > div {
    min-width: -webkit-calc(50% - 10px);
    min-width: calc(50% - 10px);
  }

  .product-featured .display-number {
    --fs-5: 1.125rem;
    margin-bottom: 0;
  }
}
