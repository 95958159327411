/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --white: #fff;
  --yellow: #c7ab62;
  --main-black: #222;
  --price-compare: #888;
  --main-gray: #444;
  --secondary-gray: #666;
  --footer-links: #999;
  --light-gray: #f6f6f6;
  --red: #e21c1c;
}

/* typography */
body,
button,
input {
  /* font-family: "DM Sans", sans-serif !important; */
  font-family: "Poppins", sans-serif !important;
}

body {
  color: var(--main-black);
}

.small-font-size {
  font-size: 13px;
  font-weight: 500;
}

/* titles styles */
.he-headers {
  position: relative;
  font-family: "Poppins", sans-serif !important;
  margin-top: 5px;
}
.he-headers span {
  color: #ebaa3a;
}

.he-headers::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10%;
  height: 2px;
  background-image: linear-gradient(to right, #ebaa3a, #0000);
  background-size: 200% 2px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  transition: background-position 0.3s;
}

.he-headers:hover::after {
  background-position: 0% 100%;
}

/* global style */
a,
img {
  -webkit-user-drag: none;
}

.multi-carousel .slider-btn {
  opacity: 0 !important;
}

.multi-carousel:hover .slider-btn {
  opacity: 1 !important;
}

.react-multi-carousel-track {
  margin-left: auto !important;
  margin-right: auto !important;
}
p {
  margin-bottom: 0;
}

/**
 * scrollbar style
 */

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-track {
  background: var(--white);
  border-left: 1px solid var(--cultured);
}

body::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 80%);
  border: 3px solid var(--white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 70%);
}

/* start custom classes */
.hover-bg-yellow:hover {
  background-color: var(--yellow) !important;
}

.hover-color-yellow:hover {
  color: var(--yellow) !important;
}

.btn-bg-dark {
  background-color: var(--main-black) !important;
}

.btn-bg-dark:hover {
  background-color: var(--yellow) !important;
}

.btn-bg-gray {
  background-color: var(--light-gray) !important;
}

.btn-bg-white {
  background-color: var(--white) !important;
}

.btn-bg-gray:hover,
.btn-bg-white:hover {
  background-color: var(--yellow) !important;
  color: white !important;
}

.btn-with-border {
  border: 2px solid var(--main-black);
  color: var(--main-black);
}

.btn-with-border:hover {
  border-color: var(--yellow);
  color: var(--yellow);
}

.text-red {
  color: var(--red);
}

.color-old-price {
  color: var(--price-compare);
}

.color-product-name {
  color: var(--secondary-gray);
}

.slider-btn {
  background-color: rgba(0, 0, 0, 0.4) !important;
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  opacity: 0;
}

.slider-btn:hover {
  background-color: var(--yellow) !important;
}

.color-main-gray {
  color: var(--main-gray);
}

.color-secondary-gray {
  color: var(--secondary-gray);
}

.color-main-black {
  color: var(--main-black);
}

.color-yellow {
  color: var(--yellow);
}

.bg-yellow {
  background-color: var(--yellow);
}

.disable-scroll {
  height: 100vh;
  overflow: hidden;
}

.disable-scroll .react-multi-carousel-track,
.disable-scroll .react-multi-carousel-item {
  -webkit-transform: unset !important;
  -ms-transform: unset !important;
  transform: unset !important;
  -webkit-transform-style: unset !important;
  transform-style: unset !important;
  will-change: unset !important;
}
/* end custom classes */

/* start main layout */
/* Start instagram posts */
.insta-posts .dragged {
  pointer-events: none;
}

.insta-posts .icon-holder {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: 10;
  -webkit-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.insta-posts .post:hover .icon-holder {
  opacity: 1;
}

.insta-posts .post img {
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.insta-posts .post:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
/* end instagram posts */

/* start brands slider*/
.brands-slider img {
  -webkit-transition: -webkit-transform ease 0.5s;
  transition: -webkit-transform ease 0.5s;
  -o-transition: transform ease 0.5s;
  transition: transform ease 0.5s;
  transition: transform ease 0.5s, -webkit-transform ease 0.5s;
}

.brands-slider img:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
/* end brands slider*/

/*Footer*/
.footer-color {
  color: var(--footer-links) !important;
}
footer {
  background-color: var(--main-black);
}
footer {
  padding-top: 3rem;
}
.footer-toggle {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-toggle .icon {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  margin-left: 1rem;
}
.footer-content {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}
.footer-content.show {
  max-height: 40rem;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.menu-footer .link {
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menu-footer .link:hover {
  display: block;
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.icon {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  margin-left: 1rem;
}
.icon::before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-rotate {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.contacts {
  text-decoration: underline;
}

/* end footer */

/* start header */
.header {
  z-index: 1040;
}

.header .nav-link.active {
  color: var(--yellow) !important;
}

.header .logo {
  width: 6rem;
}

.cart-btn .badge {
  font-size: 0.6rem;
}
/* end header */

/* start page header */
.headerCover {
  height: 10vh;
  background-image: url(./assets/page-header.jpg);
  background-size: cover;
}

.headerCover h1 {
  font-size: 1.5rem;
}

.shopping-cart {
  margin-top: 0;
}
.order_details {
  font-size: 16px;
  padding: 0 10px;
}

@media (max-width: 767.5px) {
  .headerCover h1 {
    font-size: 2.2rem;
  }

  .breadcrumb {
    font-size: 0.85rem;
  }
}
/* end page header */

/* start insta post */
.loading-img {
  background-color: #ededed !important;
  background: -o-linear-gradient(
      350deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  -webkit-animation: loading 1s ease-in-out infinite;
  animation: loading 1s ease-in-out infinite;
  aspect-ratio: 1/1;
}

@-webkit-keyframes loading {
  to {
    background-position-x: -20%;
  }
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/* end insta post */

/* start dashboard */
.dash-navlink.active {
  color: var(--dash-active-text) !important;
  background-color: var(--dash-active-bg) !important;
}

.dash-navlink.active::after {
  background-color: var(--dash-btn-bg);
}
/* end dashboard */
/* end main layout */

/* about style page */
.aboutContainer .aboutBtn {
  background-color: var(--main-black);
  color: var(--white);
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: var(--main-black) !important;
  color: white !important;
}
/* Define the color for the active tab */
.aboutContainer .nav-link.active {
  color: white;
}
.nav-tabs .nav-link {
  color: var(--main-black);
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  color: var(--main-black);
}
.nav-tabs .Tabs {
  margin-top: 0;
}
/* =====  end about page =============== */

/* ===== start scroll to top button ======= */
.flow-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  bottom: 1rem;
  right: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1000;
  -webkit-box-shadow: 1px 0px 6px rgb(203, 202, 202);
  box-shadow: 1px 0px 6px rgb(203, 202, 202);
}
.flow-button-whatsaap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* bottom: 4rem; */
  right: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1000;
  -webkit-box-shadow: 1px 0px 6px rgb(203, 202, 202);
  box-shadow: 1px 0px 6px rgb(203, 202, 202);
  background-color: #25d366;
  transition: bottom 0.3s ease;
}
.flow-button-whatsaap:hover {
  background-color: #008069;
}
.bottom-whatsaap {
  bottom: 1rem;
}
.top-whatsaap {
  bottom: 4rem;
}
@media (max-width: 767px) {
  .flow-button {
    right: 1rem;
    bottom: 3.5rem;
  }
  .flow-button-whatsaap {
    right: 1rem;
    bottom: 6.2rem;
    transition: bottom 0.3s ease;
  }
  .bottom-whatsaap {
    bottom: 3.5rem;
  }
  .top-whatsaap {
    bottom: 6.2rem;
  }
  .shopping-cart {
    margin-top: 100px;
  }
  .order_details {
    font-size: 12px;
    padding: 0 5px;
  }
}
/* ===== end scroll to top button ======= */

/* ===== start  home carousel  =======*/
#home .carousel-inner {
  height: 100%;
}
#home .carousel .carousel-control-next,
#home .carousel-control-prev {
  -webkit-filter: invert(1);
  filter: invert(1);
}

/* ====== end  home carousel ========= */

/* for cartModal*/
.cartmodal-backdrop {
  background-color: transparent !important;
}
/*end cartModal*/

/* start delete alert */
.delete-alert {
  z-index: 1045;
}

.delete-alert .close-btn {
  display: block !important;
  position: relative !important;
  margin-left: auto !important;
  padding: 0.5rem !important;
  font-size: 0.9rem;
}
/* end delete alert */

/* pagination styling */
.pagination-review {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination-review > li {
  display: inline;
}

.pagination-link {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination-link:hover {
  background-color: #f5f5f5;
}

.pagination-link:focus {
  z-index: 2;
  outline: none;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.pagination-link:focus:hover {
  background-color: #ddd;
}

.pagination-review > .active > .pagination-link,
.pagination-review > .active > .pagination-link:hover,
.pagination-review > .active > .pagination-link:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}

.pagination-review > .disabled > .pagination-link,
.pagination-review > .disabled > .pagination-link:hover,
.pagination-review > .disabled > .pagination-link:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.icon-border-rounded {
  font-size: 40px;
}

/* loader styling */

:root {
  --hue: 223;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  --primary: hsl(var(--hue), 90%, 55%);
  --trans-dur: 0.3s;
  --gold: hsl(46, 86%, 57%); /* Gold color */
}

.preloader {
  text-align: center;
  max-width: 20em;
  width: 100%;
}
.preloader__text {
  position: relative;
  height: 1.5em;
}
.preloader__msg {
  animation: msg 0.3s 13.7s linear forwards;
  position: absolute;
  width: 100%;
}
.preloader__msg--last {
  animation-direction: reverse;
  animation-delay: 14s;
  visibility: hidden;
}
.cart__loader {
  display: block;
  margin: 0 auto 1.5em auto;
  width: 8em;
  height: 8em;
}
.cart__lines,
.cart__top,
.cart__wheel1,
.cart__wheel2,
.cart__wheel-stroke {
  animation: cartLines 2s ease-in-out infinite;
}
.cart__lines {
  stroke: var(--gold); /* Change cart color to gold */
}
.cart__top {
  animation-name: cartTop;
}
.cart__wheel1 {
  animation-name: cartWheel1;
  transform: rotate(-0.25turn);
  transform-origin: 43px 111px;
}
.cart__wheel2 {
  animation-name: cartWheel2;
  transform: rotate(0.25turn);
  transform-origin: 102px 111px;
}
.cart__wheel-stroke {
  animation-name: cartWheelStroke;
  stroke: var(--gold); /* Change wheel color to gold */
}
.cart__track {
  stroke: hsla(var(--hue), 10%, 10%, 0.1);
  transition: stroke var(--trans-dur);
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 10%);
    --fg: hsl(var(--hue), 10%, 90%);
  }
  .cart__track {
    stroke: hsla(var(--hue), 10%, 90%, 0.1);
  }
}

/* Animations */
@keyframes msg {
  from {
    opacity: 1;
    visibility: visible;
  }
  99.9% {
    opacity: 0;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes cartLines {
  from,
  to {
    opacity: 0;
  }
  8%,
  92% {
    opacity: 1;
  }
}
@keyframes cartTop {
  from {
    stroke-dashoffset: -338;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 338;
  }
}
@keyframes cartWheel1 {
  from {
    transform: rotate(-0.25turn);
  }
  to {
    transform: rotate(2.75turn);
  }
}
@keyframes cartWheel2 {
  from {
    transform: rotate(0.25turn);
  }
  to {
    transform: rotate(3.25turn);
  }
}
@keyframes cartWheelStroke {
  from,
  to {
    stroke-dashoffset: 81.68;
  }
  50% {
    stroke-dashoffset: 40.84;
  }
}
.carousel__treading ul li {
  width: 190px !important;
}

/*-----------------------------------*\
  #SEARCH BOX
\*-----------------------------------*/

.mobile-search .search-container {
  position: fixed;
  inset: 0;
  /* background-color: var(--space-cadet_80); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s ease;
}

.mobile-search .search-container.active {
  opacity: 1;
  visibility: visible;
}

.mobile-search .search-container .container {
  max-width: 500px;
  width: 100%;
}

.mobile-search .search-close-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  background-color: hsl(0, 0%, 100%);
  font-size: 20px;
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.mobile-search .search-wrapper {
  position: relative;
}

.mobile-search .search-field {
  background-color: hsl(0, 0%, 100%);
  padding: 20px 40px;
  border-radius: 6px;
  color: hsl(251, 9%, 46%);
  font-weight: 500;
}

.mobile-search .search-field::-webkit-search-cancel-button {
  display: none;
}

.mobile-search .search-submit {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  margin-right: 5px;
}
